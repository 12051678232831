import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Result, { ResultProps } from '../Result';

import * as styles from './index.module.less';

interface IProps {
  title: string;
  otherLink?: React.ReactNode;
  desc?: React.ReactNode;
  result?: false | ResultProps;
  cardStyle?: React.CSSProperties;
}
export default function LoginWrap({
  title,
  otherLink,
  desc,
  result = false,
  cardStyle,
  children,
}: React.PropsWithChildren<IProps>) {
  return (
    <div className={styles.container}>
      {!result ? (
        <div className={styles.card} style={cardStyle}>
          <div className={styles.logo}>
            <div className={styles.logoImg}>
              <Link to="/">
                <StaticImage
                  height={66}
                  src="../../assets/images/logo.png"
                  alt=""
                  placeholder="none"
                />
              </Link>
              <span>土星云 - {title}</span>
            </div>
            <div className={styles.desc}>{desc}</div>
          </div>
          {children}
          {otherLink !== false && (
            <div className={styles.otherLink}>
              <Link className={styles.active} to="/">
                返回首页
              </Link>
              {otherLink}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.card}>
          <Result {...result} />
        </div>
      )}
    </div>
  );
}
