import { Link, navigate } from 'gatsby';
import { type } from 'os';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import ValidateCodeInput from '../../components/Form/CodeInput';
import Input from '../../components/Form/Input';
import LoginWrap from '../../components/LoginWrap';
import { ResultProps } from '../../components/Result';
import SEO from '../../components/SEO';
import { V_CODE_SEND } from '../../constants';
import { applyProbation } from '../../services';
import { ApplyProbationBody } from '../../services/interface';

import * as styles from './index.module.less';

export default function Probation() {
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState<ApplyProbationBody>({
    phone: '',
    code: 0,
  });
  const [result, setResult] = useState<ResultProps>();

  function submitHandler(e: React.FormEvent) {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    applyProbation({ ...formValue, code: Number(formValue.code) })
      .then((res) => {
        setResult({
          type: 'success',
          title: '申请成功',
          desc: (
            <div style={{ textAlign: 'center' }}>
              <div>
                <strong>
                  此账号仅供试用，有效期 48 小时。
                  <br />
                  48 小时后账号将会失效，并清理内容。
                  <br />
                  请勿上传正式数据！
                </strong>
              </div>
              <br />
              <div>
                账号：<span>{res.data.data.user_name}</span>
              </div>
              <div>
                密码：<span>{res.data.data.password}</span>
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <Link
                  to={`/login?username=${encodeURIComponent(
                    res.data.data.user_name,
                  )}&password=${decodeURIComponent(res.data.data.password)}`}>
                  <Button type="primary">去登录</Button>
                </Link>
              </div>
            </div>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <LoginWrap
      title="申请试用账号"
      desc={
        <>
          <div>填写手机号与验证码提交后，会为您提供测试账号</div>
          <div>您可以试用测试账号试用系统</div>
        </>
      }
      otherLink={
        <>
          <Link to="/login">登录</Link>
          <Link to="/register">注册</Link>
        </>
      }
      result={result}>
      <form onSubmit={submitHandler}>
        <div className={styles.formItem}>
          <Input
            type="phone"
            name="phone"
            required
            placeholder="11 位手机号"
            value={formValue.phone}
            pattern="^(?:(?:\+|00)86)?1\d{10}$"
            title="手机号码格式不正确"
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                phone: e.target.value,
              }));
            }}
          />
        </div>
        <div className={styles.formItem}>
          <ValidateCodeInput
            phone={formValue.phone}
            sendType={V_CODE_SEND.TEST_CODE.value}
            placeholder="请输入验证码"
            autoComplete="off"
            required
            name="code"
            value={formValue.code || ''}
            onChange={(e) => {
              setFormValue((state) => ({
                ...state,
                code: e.target.value,
              }));
            }}
          />
        </div>

        <div className={styles.formItem}>
          <Button type="primary" block loading={loading} htmlType="submit">
            确认申请
          </Button>
        </div>
      </form>
    </LoginWrap>
  );
}

export function Head() {
  return <SEO title="注册" />;
}
