import React, { useState, useRef } from 'react';
import type { V_CODE_SEND_TYPE } from '../../../constants';
import { sendSmsCode } from '../../../services';
import * as styles from './index.module.less';
import Input, { InputProps } from '../Input';
import Button from '../../Button';
import { notice } from '../../Box';

const DEF_TEST = '发送验证码';

interface SendButtonProps {
  phone: string;
  sendType: V_CODE_SEND_TYPE;
}
export function SendButton({ phone, sendType }: SendButtonProps) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState(DEF_TEST);
  const timer = useRef(0);

  const looperTimer = () => {
    if (timer.current === 0) {
      setText(DEF_TEST);
      setDisabled(false);
      return;
    }
    setTimeout(() => {
      timer.current -= 1;
      const newText = `${timer.current}s 后重新发送`;
      setText(newText);

      looperTimer();
    }, 1000);
  };

  const submitHandler = () => {
    if (!/^(?:(?:\+|00)86)?1\d{10}$/.test(phone)) {
      notice.error({ title: '手机号码格式不正确' });
      return;
    }
    if (loading) return;
    setLoading(true);
    setText('发送中 ...');
    sendSmsCode({
      phone,
      send_type: sendType,
    })
      .then(() => {
        timer.current = 60;
        setDisabled(true);
        looperTimer();
      })
      .catch(() => {
        setText(DEF_TEST);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      type="primary"
      loading={loading}
      onClick={submitHandler}
      disabled={phone.toString().length !== 11 || disabled}
      formNoValidate>
      {text}
    </Button>
  );
}

export interface ValidateCodeInputProps extends SendButtonProps, InputProps {}

export default function ValidateCodeInput({
  sendType,
  phone,
  placeholder = '请输入验证码',
  ...props
}: ValidateCodeInputProps) {
  return (
    <div className={styles.codeInput}>
      <Input className={styles.input} type="text" placeholder={placeholder} {...props} />
      <SendButton phone={phone} sendType={sendType} />
    </div>
  );
}
